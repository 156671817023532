import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import moment from 'moment';
import { CombinedProvider } from "./context/CombinedProvider";
import { adToBs, bsToAd } from '@sbmdkl/nepali-date-converter'; // Import the date conversion functions
import { useAnonymous } from "./context/anonymousContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Create a wrapper component to set global variables
const AppWrapper = () => {
  const { anonymous, slug, submissionId, loading, setLoading,status } = useAnonymous();
  // Set global variables
  window.moment = moment;
  window.anonymous = anonymous;
  window.adToBs = adToBs; // Set adToBs globally
  window.bsToAd = bsToAd; // Set bsToAd globally
  window.status=status;
  return <App />;
};

root.render(
  <BrowserRouter>
    <CombinedProvider>
      <AppWrapper />
    </CombinedProvider>
  </BrowserRouter>
);

reportWebVitals();
