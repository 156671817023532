import React, { createContext, useContext, useState } from "react";

// Create the context
const AnonymousContext = createContext();

// Create a provider component
export const AnonymousProvider = ({ children }) => {
  const [anonymous, setAnonymous] = useState(false);
  const [isAnonymousEditMode, setIsAnonymousEditMode] = useState(false);
  const [slug, setSlug] = useState(""); // State for slug
  const [steps,setSteps]=useState("");
  const [status,setStatus]=useState("")
  const [submissionId, setSubmissionId] = useState(""); // State for submission ID
  const [loading, setLoading] = useState(false); 
  const[isAccountValidation,setIsAccountValidation]=useState(false);
  return (
    <AnonymousContext.Provider 
      value={{ 
        anonymous, 
        setAnonymous, 
        isAnonymousEditMode, 
        setIsAnonymousEditMode, 
        slug, 
        setSlug, 
        submissionId, 
        setSubmissionId,
        steps,
        status,
        isAccountValidation,
        setIsAccountValidation,
        setStatus,
        setSteps,
        loading, // Provide loading state
        setLoading // Provide setter for loading state
      }}
    >
      {children}
    </AnonymousContext.Provider>
  );
};

// Custom hook to use the Anonymous context
export const useAnonymous = () => useContext(AnonymousContext);
