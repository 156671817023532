import React, { useState, useEffect, useRef } from 'react';
import Form from '@rjsf/antd';
import validator, { customizeValidator } from "@rjsf/validator-ajv8";
import { transformErrors } from '../constants/transformErrors';
import CustomSelectWidget from '../widgets/CustomSelectWidget';
import ajvErrors from 'ajv-errors';
import { json, useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomDatePicker from '../widgets/CustomDatePicker';
import CustomFileUpload from '../widgets/CustomFileUpload';
import NepaliDatePicker from '../widgets/NepaliDatePicker';
import useFetchToken from '../hooks/useFetchToken';
import { Button, Col, message, Row, Spin } from 'antd';
import { useAnonymous } from '../context/anonymousContext';
import { FileUploadProvider, useFileUpload } from '../context/FileUploadContext';
import useFetchFormData from '../hooks/useFetchFormData';
import { adToBs, bsToAd } from '@sbmdkl/nepali-date-converter';
// import useCreateDraft from '../hooks/useCreateDraft';
import { useDraft } from '../context/DraftContext';

export const dynamicFormValidator = customizeValidator();
ajvErrors(dynamicFormValidator.ajv);

const CreditCardForm = () => {
  const { anonymous, setAnonymous,setSubmissionId,submissionId:contextSubmissionId , status} = useAnonymous();
  const [skipValidation, setSkipValidation] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const { id: paramsId, stepslug, slug } = useParams();
  const { data: fetchedData, loading: dataLoading } = useFetchFormData(paramsId);
  console.log(fetchedData,"hay")
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [jsonSchema, setJsonSchema] = useState(null);
  const [uiSchema, setUiSchema] = useState(null);
  const previousFormData = useRef({});
  const location = useLocation();
  const { state } = location;
  const customerData = state?.customerData;
  console.log(customerData,"hay")
  console.log(contextSubmissionId,"hay");
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const { loading: tokenLoading, error: tokenError } = useFetchToken();
  const token = sessionStorage.getItem('token');
  const { fileNames } = useFileUpload();
  const [globalErrorMessage, setGlobalErrorMessage] = useState('');
  const formKey = `${formData.dateOfBirthAD}-${formData.dateOfBirthBS}`;
  const { createDraft, isCreatingDraft } = useDraft();

  // useEffect(() => {
  //   // Load the branding script
  //   const script = document.createElement('script');
  //   script.src = '/branding.js'; // Assuming the script is in the public folder
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  useEffect(() => {
    const fetchInitialSchemas = async () => {
      if (dataLoading || tokenLoading) {
        // message.info('Fetching data, please wait...');
        return;
      }

      if (tokenError) {
        message.error('Unable to submit form due to data retrieval failure.');
        return;
      }

      try {
        const jsonSchemaResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/forms/new-credit-card`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const jsonResponse = await jsonSchemaResponse.json();
        setAnonymous(jsonResponse.data.anonymous === 1);
          // if (jsonResponse.data.branding_script) {
          //   const brandingScript = jsonResponse.data.branding_script;
  
          //   if (window) {
          //     // Check if branding script has already been added
          //     if (!document.querySelector('script[data-type="branding"]')) {
          //       const scriptElement = document.createElement('script');
          //       scriptElement.type = 'text/javascript';
          //       scriptElement.setAttribute('data-type', 'branding');
          //       scriptElement.text = brandingScript;
          //       document.head.appendChild(scriptElement);
          //     }
          //   }
          // }
        const stepData = jsonResponse.data.steps[0];
        const jsonSchemaData = JSON.parse(stepData.form_schema);
        const executeScript = new Function(stepData.ui_schema);
        executeScript();
        let initialFormData = {};
        if (fetchedData) {
          initialFormData = fetchedData.form_data;
        } else if (!anonymous && customerData) {
          initialFormData = {
            accountNumber: customerData?.account_number || "",
            mobileNumber: customerData?.mobile_number || "",
            emailAddress: customerData?.email_address || ""
          };
        }

        if (window.UISchemaFactory) {
          const factory = new window.UISchemaFactory();
          const uiSchemaData = factory.createUISchema({
            formData: initialFormData,
            setFormData,
            jsonSchema: jsonSchemaData,
            widgets: { CustomSelectWidget, CustomDatePicker, CustomFileUpload, NepaliDatePicker },
            setJsonSchema,
            state,
            anonymous,
            adToBs,
            bsToAd,
            status
          });

          setJsonSchema(jsonSchemaData);
          setUiSchema(uiSchemaData);
          setFormData(initialFormData);
        }
        // fetchedData?.request_status === 'draft'
        if ( fetchedData?.include_account_validation === "Yes") {
          const isVerified = sessionStorage.getItem('verified');
        
          if (isVerified) {
            // If the user is already verified, navigate to the next step or display a message
            // message.info('You are already verified. Redirecting...');
            navigate(`/services/${slug}/${stepslug}/${paramsId}`);
          } else {
            // If not verified, navigate to OTP form and show the message
            navigate(`/services/${slug}/${stepslug}/otp-form/${fetchedData?.request_id}`);
            message.success("Check your Email for OTP");
          }
        }
        
      } catch (error) {
        console.error('Error fetching schemas:', error);
      }

    };
    fetchInitialSchemas();
  }, [token, state, anonymous, fetchedData, setAnonymous, dataLoading, tokenLoading, tokenError]);

  const onChange = ({ formData }) => {
    setFormData(formData);
    const updatedErrors = { ...serverErrors };
    Object.keys(serverErrors).forEach((field) => {
      if (formData[field] && formData[field] !== previousFormData.current[field]) {
        delete updatedErrors[field];
      }
    });
    setServerErrors(updatedErrors);
    previousFormData.current = formData;
  };

  const handleFormSubmission = async (formData, submissionType) => {
    const { ppSizePhoto, citizenship } = fileNames;
    const endpoint = `${process.env.REACT_APP_BASE_URL}/forms/submit`;

    let submissionId = paramsId || contextSubmissionId || customerData?.request_id;

    if (!submissionId) {
      try {
        submissionId = await createDraft(slug, stepslug, token);
        if (!submissionId) {
          message.error('Failed to create draft. Please try again.');
          return;
        }
        setSubmissionId(submissionId);
      } catch (error) {
        console.error('Error creating draft:', error);
        message.error('Failed to create draft. Please try again.');
        return;
      }
    }

    if (!submissionId) {
      message.error('Unable to determine submission ID. Please try again.');
      return;
    }

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          form_slug: slug,
          step_slug: stepslug,
          request_id: submissionId,
          submission_type: submissionType,
          form_data: {
            ...formData,
            ppSizePhoto: ppSizePhoto || formData.ppSizePhoto || "",
            citizenship: citizenship || formData.citizenship || "",
          },
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        handleServerErrors(result);
      } else {
        navigateToSuccessPage(submissionId, submissionType); 
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Submission failed. Please try again.');
    }
  };


  const onSubmit = async ({ formData }) => {
    await handleFormSubmission(formData, 'submitted');
  };

  const customValidate = (formData, errors) => {
    if (skipValidation) {
      return {};
    }
    
    Object.entries(serverErrors).forEach(([field, fieldErrors]) => {
      if (Array.isArray(fieldErrors)) {
        fieldErrors.forEach((item, index) => {
          if (errors[field] && errors[field][index]) {
            Object.entries(item).forEach(([subField, error]) => {
              if (errors[field][index][subField]) {
                errors[field][index][subField].addError(error);
              }
            });
          }
        });
      } else if (typeof fieldErrors === 'object' && fieldErrors !== null) {
        if (Object.values(fieldErrors).every(val => typeof val === 'object')) {
          Object.entries(fieldErrors).forEach(([index, indexErrors]) => {
            if (errors[field] && errors[field][index]) {
              Object.entries(indexErrors).forEach(([subField, error]) => {
                if (errors[field][index][subField]) {
                  errors[field][index][subField].addError(error);
                }
              });
            }
          });
        } else {
          if (errors[field]) {
            errors[field].addError(fieldErrors);
          }
        }
      } else {
        if (errors[field]) {
          errors[field].addError(fieldErrors.toString());
        }
      }
    });
    
    return errors;
  };

  const handleSubmit = async (submissionType) => {
    setSkipValidation(submissionType === 'draft');
    
    if (submissionType === 'draft') {
      await handleFormSubmission(formData, 'draft');
    } else {
      const form = document.querySelector('form');
      if (form) {
        form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }
  };



  const handleServerErrors = (errorData) => {
    if (Array.isArray(errorData) && errorData.length > 0) {
      errorData = errorData[0];
    }

    if (errorData.globalErrorMessage) {
      message.error(errorData.globalErrorMessage);
      setGlobalErrorMessage(errorData.globalErrorMessage);
    }

    

    if (errorData.fieldLevelErrors) {
      const newErrors = {};
      Object.entries(errorData.fieldLevelErrors).forEach(([field, errors]) => {
        if (Array.isArray(errors)) {
          newErrors[field] = errors.map(item => 
            Object.entries(item).reduce((acc, [subField, subErrors]) => {
              acc[subField] = Object.values(subErrors).join(', ');
              return acc;
            }, {})
          );
        } else if (typeof errors === 'object' && errors !== null) {
          if (Object.values(errors).every(val => typeof val === 'object')) {
            newErrors[field] = Object.entries(errors).reduce((acc, [index, indexErrors]) => {
              acc[index] = Object.entries(indexErrors).reduce((subAcc, [subField, subErrors]) => {
                subAcc[subField] = Object.values(subErrors).join(', ');
                return subAcc;
              }, {});
              return acc;
            }, {});
          } else {
            newErrors[field] = Object.values(errors).join(', ');
          }
        } else {
          newErrors[field] = errors.toString();
        }
      });
      setServerErrors(newErrors);
    }
    if (errorData.error) {
      message.error(errorData.error);
      return;
    }
  };

  const navigateToSuccessPage = (id, submissionType) => {
    const draftUrl = `https://bpm-form.server247.info/services/new-credit-card/apply/${id}`;
    navigate(`/services/${slug}/${stepslug}/success-page`, { state: { id: id, draftUrl, submissionType } });
  };

  if (!jsonSchema || !uiSchema) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" spinning={true} />
      </div>
    );
  }
  

  return (
    <FileUploadProvider>
      <Form
        key={formKey} 
        schema={jsonSchema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={onChange}
        onSubmit={onSubmit}
        customValidate={customValidate}
        validator={dynamicFormValidator}
          transformErrors={(errors) => transformErrors(errors, jsonSchema)}
        noHtml5Validate
        showErrorList={false}
        widgets={{
          CustomSelectWidget,
          CustomDatePicker,
          CustomFileUpload,
          NepaliDatePicker
        }}
        formContext={formData}
      >
        <div style={{ marginTop: '16px' }}>
          <Row justify="space-between">
            <Col>
              <Button
                type="default"
                onClick={() => handleSubmit('draft')}
                disabled={status === 'submitted'}
              >
                Save as Draft
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => handleSubmit('submitted')}
                disabled={status === 'submitted'}
              >
                Save and Continue
              </Button>
            </Col>
          </Row>
         
        </div>
      </Form>
    </FileUploadProvider>
  );
};

export default CreditCardForm;