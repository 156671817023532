import React from 'react';
import { Select } from 'antd';
import { useAnonymous } from '../context/anonymousContext';

const { Option } = Select;

const CustomSelectWidget = (props) => {
  const { options, value, onChange: onFormChanged } = props;
  const { enumOptions, onChange } = options;
  const {status } = useAnonymous();
 

  const handleChange = (value) => {
    if(onChange) {
        onChange(value);
    }
    onFormChanged(value);
  };

  return (
    <Select value={value} onChange={handleChange} placeholder="Select an option" disabled={status === 'submitted'}>
      {(enumOptions || []).map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelectWidget;
