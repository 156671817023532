import React, { createContext, useState, useContext } from 'react';

const FileUploadContext = createContext();

export const FileUploadProvider = ({ children }) => {
  const [fileNames, setFileNames] = useState({});

  const updateFileName = (field, fileName) => {
    setFileNames((prev) => ({
      ...prev,
      [field]: fileName,
    }));
  };

  return (
    <FileUploadContext.Provider value={{ fileNames, updateFileName }}>
      {children}
    </FileUploadContext.Provider>
  );
};

export const useFileUpload = () => useContext(FileUploadContext);
