import React, { useEffect } from 'react';
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useAnonymous } from '../context/anonymousContext';
import useFetchSlugAndSubmissionId from '../hooks/useFetchSlugAndSubmissionId';
import useFetchToken from '../hooks/useFetchToken';

function Landingpage() {
  const navigate = useNavigate();
  const { loading: formLoading, error: formError } = useFetchSlugAndSubmissionId();
  const { token, loading: tokenLoading, error: tokenError } = useFetchToken();
  const { anonymous, slug, steps, loading, isAccountValidation } = useAnonymous();
  console.log(isAccountValidation,"hay")

  useEffect(() => {
    if (!loading && !tokenLoading && slug && steps && steps.length > 0) {
      
      if (isAccountValidation) {
        navigate(`/services/${slug}/${steps[0]}/account-validation`);
   
      } else {
        navigate(`/services/${slug}/${steps[0]}`);
      }
    }
  }, [loading, tokenLoading, slug, steps, anonymous, navigate]);

  if (loading || tokenLoading || formLoading) {
    return (
      <div style={styles.loadingContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return null;
}

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    // backgroundColor: "#f0f2f5",
  },
};

export default Landingpage;
