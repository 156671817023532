import { useState } from "react";
import { useAnonymous } from "../context/anonymousContext";
import { useParams } from "react-router-dom";

const useCreateDraft = () => {
  const token = sessionStorage.getItem('token');
  const {  setSubmissionId } = useAnonymous();
  const { slug,stepslug } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createDraft = async () => {
    if (!token || !slug ) return null;
    
    setLoading(true);
    try {
      const draftResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/forms/create-draft`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          form_slug: slug,
          form_step: stepslug
        }),
      });

      if (draftResponse.ok) {
        const draftData = await draftResponse.json();
        setSubmissionId(draftData.request_id);
        return draftData.request_id;  // Return the new submission ID
      } else {
        setError("Failed to create draft request.");
        return null;
      }
    } catch (error) {
      setError("An error occurred while creating the draft.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { createDraft, loading, error };
};

export default useCreateDraft;