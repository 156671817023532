// DraftContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useAnonymous} from './anonymousContext';

const DraftContext = createContext();

export const useDraft = () => useContext(DraftContext);

export const DraftProvider = ({ children }) => {

    const { setSubmissionId,submissionId } = useAnonymous();
    console.log(submissionId,"hay")
  const [draftId, setDraftId] = useState(null); // State to store request_id
  const [isCreatingDraft, setIsCreatingDraft] = useState(false); // To control draft creation

  const createDraft = useCallback(async (slug, stepslug, token) => {
    if (!slug || !token) return null;
    
    try {
      setIsCreatingDraft(true); // Lock the creation process
      const draftResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/forms/create-draft`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          form_slug: slug,
          form_step: stepslug
        }),
      });

      if (draftResponse.ok) {
        const draftData = await draftResponse.json();
        setDraftId(draftData.request_id);
        setSubmissionId(draftData.request_id); // Update submission_id in anonymous context
         // Save the request_id in state
        return draftData.request_id;
      } else {
        console.error("Failed to create draft request.");
        return null;
      }
    } catch (error) {
      console.error("An error occurred while creating the draft:", error);
      return null;
    } finally {
      setIsCreatingDraft(false); // Release the lock after the request is completed
    }
  }, []);

  return (
    <DraftContext.Provider value={{ draftId, createDraft, isCreatingDraft }}>
      {children}
    </DraftContext.Provider>
  );
};
