import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoutes from "./protectedRoutes";
import AccountValidationForm from "../pages/AccountValidationForm";
import OtpForm from "../pages/OtpForm";
import CreditCardForm from "../pages/CreditCardForm";
import SuccessPage from "../pages/SuccessPage";
import Landingpage from "../pages/Landingpage";

const MainRoutes = () => {
  return (
    <Routes>
      {/* Redirect root to the desired path */}
      {/* <Route 
        path="/" 
        element={<Navigate to="/new-credit-card/application-form" replace />} 
      /> */}
      <Route 
          path="/services/new-credit-card" 
          element={<Landingpage />} 
        />
      <Route element={<ProtectedRoutes />}>
        <Route 
          path="/services/:slug/:stepslug/account-validation" 
          element={<AccountValidationForm />} 
        />
        <Route 
          path="/services/:slug/:stepslug/otp-form" 
          element={<OtpForm />} 
        />
        <Route 
          path="/services/:slug/:stepslug/otp-form/:id" 
          element={<OtpForm />} 
        />
        {/* <Route 
          path="/:slug/application-form" 
          element={<CreditCardForm />} 
        /> */}
         <Route 
          path="/services/:slug/:stepslug" 
          element={<CreditCardForm />} 
        />
           <Route 
          path="/services/:slug/:stepslug/:id" 
          element={<CreditCardForm />} 
        />
        <Route 
          path="/services/:slug/:stepslug/success-page" 
          element={<SuccessPage />} 
        />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
