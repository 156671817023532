// import { useState, useEffect } from 'react';
// import { message } from 'antd';

// const useFetchToken = () => {
//   const [token, setToken] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchToken = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email: process.env.REACT_APP_EMAIL,
//           password: process.env.REACT_APP_PASSWORD,
//         }),
//       });

//       const result = await response.json();
//       if (response.ok) {
//         setToken(result.token); 
//         sessionStorage.setItem('token', result.token);
//         sessionStorage.setItem('expires_at', result.expires_at);
//         setLoading(false);
//       } else {
//         message.error('Failed to retrieve token.');
//         setError('Failed to retrieve token');
//         setLoading(false);
//       }
//     } catch (error) {
//       console.error('Error fetching token:', error);
//       message.error('An error occurred while fetching the token.');
//       setError(error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchToken();
//   }, []);

//   return { token, loading, error };
// };

// export default useFetchToken;








import { useState, useEffect } from 'react';
import { message } from 'antd';

const useFetchToken = () => {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to check token expiration
    const isTokenExpired = (expiry) => {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        return currentTime > expiry;
    };

    // Function to fetch a new token
    const fetchToken = async () => {
        setLoading(true); // Start loading when fetching token
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: process.env.REACT_APP_EMAIL,
                    password: process.env.REACT_APP_PASSWORD,
                }),
            });
    
            const result = await response.json();
            if (response.ok) {
                // Store token and expiration time
                const { token: newToken, expires_at } = result; // Adjust if your API response is different
                // const expiryInSeconds = Math.floor(new Date(expires_at).getTime() / 1000); // Convert expiration time to seconds
                sessionStorage.setItem('token', newToken);
                // sessionStorage.setItem('expires_at', expiryInSeconds.toString()); // Store as string
                sessionStorage.setItem('expires_at', expires_at);
                setToken(newToken);
            } else {
                message.error('Failed to retrieve token.');
                setError('Failed to retrieve token');
            }
        } catch (error) {
            console.error('Error fetching token:', error);
            message.error('An error occurred while fetching the token.');
            setError(error);
        } finally {
            setLoading(false); // Stop loading after the API call
        }
    };
    

    useEffect(() => {
        const initializeToken = () => {
            const storedToken = sessionStorage.getItem('token');
            const storedExpiration = sessionStorage.getItem('expires_at');

            if (storedToken && storedExpiration) {
                // Check if the token has expired
                if (!isTokenExpired(storedExpiration)) {
                    // Token is valid, use it
                    setToken(storedToken);
                    setLoading(false);
                } else {
                    // Token expired, fetch a new one
                    fetchToken();
                }
            } else {
                // No token in storage, fetch a new one
                fetchToken();
            }
        };

        initializeToken();
    }, []); // Runs only once when the component mounts

    return { token, loading, error };
};

export default useFetchToken;
