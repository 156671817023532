import { useState, useEffect } from 'react';
import useFetchToken from './useFetchToken';
import { useAnonymous } from '../context/anonymousContext';
import { message } from 'antd';


const useFetchFormData = (submissionId) => {
  console.log(submissionId,"getDaata")
  const token=sessionStorage.getItem('token')
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { setStatus,setAnonymous,slug,steps} = useAnonymous();


  useEffect(() => {
    const fetchFormData = async () => {
      if (!submissionId) return;

      setLoading(true);
      message.info('Fetching data, please wait...');
      setError(null);

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/forms/submissions/apply/${submissionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setData(data);
          setStatus(data.request_status)
          setAnonymous(data.is_anonymous === 1)
        } else {
          setError('Failed to retrieve form data. Please check the Request ID and try again.');
        }
      } catch (err) {
        setError('An error occurred while retrieving the form data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchFormData();
  }, [submissionId]);
console.log(data,"hello")
  return { loading, data, error };
};

export default useFetchFormData;
